/** @jsx jsx */
import { Box, Flex } from "@theme-ui/components"
import { Link } from "gatsby"
import kebabCase from "lodash.kebabcase"
import { Heading, jsx, Link as TLink } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import Layout from "./layout"
import SEO from "./seo"

const TagsComponent = ({ list }) => {
  const { tagsPath, basePath } = useBlogConfig()

  return (
    <Layout>
      <SEO title="Tags" />
      <Heading variant="styles.h2">Tags</Heading>
      <Box mt={[4, 5]}>
        {list.map(listItem => (
          <Flex
            key={listItem.fieldValue}
            mb={[1, 1, 2]}
            sx={{ alignItems: `center` }}
          >
            <TLink
              as={Link}
              sx={{ variant: `links.listItem`, mr: 2 }}
              to={replaceSlashes(
                `/${basePath}/${tagsPath}/${kebabCase(listItem.fieldValue)}`
              )}
            >
              {listItem.fieldValue}{" "}
              <span sx={{ color: `secondary` }}>({listItem.totalCount})</span>
            </TLink>
          </Flex>
        ))}
      </Box>
    </Layout>
  )
}

const Tags = ({ data }) => {
  const { allPost } = data

  return <TagsComponent list={allPost.group} />
}

export default Tags
